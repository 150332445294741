
const countryOptions = [
    { key: 'us', value: 'USA', text: 'United States' },
    { key: 'ca', value: 'Canada', text: 'Canada' },
    { key: 'ad', value: 'AD', text: 'Andorra' },
    { key: 'ae', value: 'AE', text: 'United Arab Emirates' },
    { key: 'af', value: 'AF', text: 'Afghanistan' },
    { key: 'ag', value: 'AG', text: 'Antigua and Barbuda' },
    { key: 'ai', value: 'AI', text: 'Anguilla' },
    { key: 'al', value: 'AL', text: 'Albania' },
    { key: 'am', value: 'AM', text: 'Armenia' },
    { key: 'ao', value: 'AO', text: 'Angola' },
    { key: 'aq', value: 'AQ', text: 'Antarctica' },
    { key: 'ar', value: 'AR', text: 'Argentina' },
    { key: 'as', value: 'AS', text: 'American Samoa' },
    { key: 'at', value: 'AT', text: 'Austria' },
    { key: 'au', value: 'AU', text: 'Australia' },
    { key: 'aw', value: 'AW', text: 'Aruba' },
    { key: 'ax', value: 'AX', text: 'Aland Islands' },
    { key: 'az', value: 'AZ', text: 'Azerbaijan' },
    { key: 'ba', value: 'BA', text: 'Bosnia and Herzegovina' },
    { key: 'bb', value: 'BB', text: 'Barbados' },
    { key: 'bd', value: 'BD', text: 'Bangladesh' },
    { key: 'be', value: 'BE', text: 'Belgium' },
    { key: 'bf', value: 'BF', text: 'Burkina Faso' },
    { key: 'bg', value: 'BG', text: 'Bulgaria' },
    { key: 'bh', value: 'BH', text: 'Bahrain' },
    { key: 'bi', value: 'BI', text: 'Burundi' },
    { key: 'bj', value: 'BJ', text: 'Benin' },
    { key: 'bl', value: 'BL', text: 'Saint Barthélemy' },
    { key: 'bm', value: 'BM', text: 'Bermuda' },
    { key: 'bn', value: 'BN', text: 'Brunei Darussalam' },
    { key: 'bo', value: 'BO', text: 'Bolivia' },
    { key: 'bq', value: 'BQ', text: 'Bonaire, Sint Eustatius and Saba' },
    { key: 'br', value: 'BR', text: 'Brazil' },
    { key: 'bs', value: 'BS', text: 'Bahamas' },
    { key: 'bt', value: 'BT', text: 'Bhutan' },
    { key: 'bv', value: 'BV', text: 'Bouvet Island' },
    { key: 'bw', value: 'BW', text: 'Botswana' },
    { key: 'by', value: 'BY', text: 'Belarus' },
    { key: 'bz', value: 'BZ', text: 'Belize' },
    { key: 'cc', value: 'CC', text: 'Cocos (Keeling) Islands' },
    { key: 'cd', value: 'CD', text: 'Congo, Democratic Republic of the' },
    { key: 'cf', value: 'CF', text: 'Central African Republic' },
    { key: 'cg', value: 'CG', text: 'Congo' },
    { key: 'ch', value: 'CH', text: 'Switzerland' },
    { key: 'ci', value: 'CI', text: "Cote d'Ivoire" },
    { key: 'ck', value: 'CK', text: 'Cook Islands' },
    { key: 'cl', value: 'CL', text: 'Chile' },
    { key: 'cm', value: 'CM', text: 'Cameroon' },
    { key: 'cn', value: 'CN', text: 'China' },
    { key: 'co', value: 'CO', text: 'Colombia' },
    { key: 'cr', value: 'CR', text: 'Costa Rica' },
    { key: 'cu', value: 'CU', text: 'Cuba' },
    { key: 'cv', value: 'CV', text: 'Cape Verde' },
    { key: 'cw', value: 'CW', text: 'Curaçao' },
    { key: 'cx', value: 'CX', text: 'Christmas Island' },
    { key: 'cy', value: 'CY', text: 'Cyprus' },
    { key: 'cz', value: 'CZ', text: 'Czech Republic' },
    { key: 'de', value: 'DE', text: 'Germany' },
    { key: 'dj', value: 'DJ', text: 'Djibouti' },
    { key: 'dk', value: 'DK', text: 'Denmark' },
    { key: 'dm', value: 'DM', text: 'Dominica' },
    { key: 'do', value: 'DO', text: 'Dominican Republic' },
    { key: 'dz', value: 'DZ', text: 'Algeria' },
    { key: 'ec', value: 'EC', text: 'Ecuador' },
    { key: 'ee', value: 'EE', text: 'Estonia' },
    { key: 'eg', value: 'EG', text: 'Egypt' },
    { key: 'eh', value: 'EH', text: 'Western Sahara' },
    { key: 'er', value: 'ER', text: 'Eritrea' },
    { key: 'es', value: 'ES', text: 'Spain' },
    { key: 'et', value: 'ET', text: 'Ethiopia' },
    { key: 'fi', value: 'FI', text: 'Finland' },
    { key: 'fj', value: 'FJ', text: 'Fiji' },
    { key: 'fk', value: 'FK', text: 'Falkland Islands (Malvinas)' },
    { key: 'fm', value: 'FM', text: 'Micronesia, Federated States of' },
    { key: 'fo', value: 'FO', text: 'Faroe Islands' },
    { key: 'fr', value: 'FR', text: 'France' },
    { key: 'ga', value: 'GA', text: 'Gabon' },
    { key: 'gb', value: 'GB', text: 'United Kingdom' },
    { key: 'gd', value: 'GD', text: 'Grenada' },
    { key: 'ge', value: 'GE', text: 'Georgia' },
    { key: 'gf', value: 'GF', text: 'French Guiana' },
    { key: 'gg', value: 'GG', text: 'Guernsey' },
    { key: 'gh', value: 'GH', text: 'Ghana' },
    { key: 'gi', value: 'GI', text: 'Gibraltar' },
    { key: 'gl', value: 'GL', text: 'Greenland' },
    { key: 'gm', value: 'GM', text: 'Gambia' },
    { key: 'gn', value: 'GN', text: 'Guinea' },
    { key: 'gp', value: 'GP', text: 'Guadeloupe' },
    { key: 'gq', value: 'GQ', text: 'Equatorial Guinea' },
    { key: 'gr', value: 'GR', text: 'Greece' },
    { key: 'gs', value: 'GS', text: 'South Georgia and the South Sandwich Islands' },
    { key: 'gt', value: 'GT', text: 'Guatemala' },
    { key: 'gu', value: 'GU', text: 'Guam' },
    { key: 'gw', value: 'GW', text: 'Guinea-Bissau' },
    { key: 'gy', value: 'GY', text: 'Guyana' },
    { key: 'hk', value: 'HK', text: 'Hong Kong' },
    { key: 'hm', value: 'HM', text: 'Heard Island and McDonald Islands' },
    { key: 'hn', value: 'HN', text: 'Honduras' },
    { key: 'hr', value: 'HR', text: 'Croatia' },
    { key: 'ht', value: 'HT', text: 'Haiti' },
    { key: 'hu', value: 'HU', text: 'Hungary' },
    { key: 'id', value: 'ID', text: 'Indonesia' },
    { key: 'ie', value: 'IE', text: 'Ireland' },
    { key: 'il', value: 'IL', text: 'Israel' },
    { key: 'im', value: 'IM', text: 'Isle of Man' },
    { key: 'in', value: 'IN', text: 'India' },
    { key: 'io', value: 'IO', text: 'British Indian Ocean Territory' },
    { key: 'iq', value: 'IQ', text: 'Iraq' },
    { key: 'ir', value: 'IR', text: 'Iran, Islamic Republic of' },
    { key: 'is', value: 'IS', text: 'Iceland' },
    { key: 'it', value: 'IT', text: 'Italy' },
    { key: 'je', value: 'JE', text: 'Jersey' },
    { key: 'jm', value: 'JM', text: 'Jamaica' },
    { key: 'jo', value: 'JO', text: 'Jordan' },
    { key: 'jp', value: 'JP', text: 'Japan' },
    { key: 'ke', value: 'KE', text: 'Kenya' },
    { key: 'kg', value: 'KG', text: 'Kyrgyzstan' },
    { key: 'kh', value: 'KH', text: 'Cambodia' },
    { key: 'ki', value: 'KI', text: 'Kiribati' },
    { key: 'km', value: 'KM', text: 'Comoros' },
    { key: 'kn', value: 'KN', text: 'Saint Kitts and Nevis' },
    { key: 'kp', value: 'KP', text: "Korea, Democratic People's Republic of" },
    { key: 'kr', value: 'KR', text: 'Korea, Republic of' },
    { key: 'kw', value: 'KW', text: 'Kuwait' },
    { key: 'ky', value: 'KY', text: 'Cayman Islands' },
    { key: 'kz', value: 'KZ', text: 'Kazakhstan' },
    { key: 'la', value: 'LA', text: "Lao People's Democratic Republic" },
    { key: 'lb', value: 'LB', text: 'Lebanon' },
    { key: 'lc', value: 'LC', text: 'Saint Lucia' },
    { key: 'li', value: 'LI', text: 'Liechtenstein' },
    { key: 'lk', value: 'LK', text: 'Sri Lanka' },
    { key: 'lr', value: 'LR', text: 'Liberia' },
    { key: 'ls', value: 'LS', text: 'Lesotho' },
    { key: 'lt', value: 'LT', text: 'Lithuania' },
    { key: 'lu', value: 'LU', text: 'Luxembourg' },
    { key: 'lv', value: 'LV', text: 'Latvia' },
    { key: 'ly', value: 'LY', text: 'Libya' },
    { key: 'ma', value: 'MA', text: 'Morocco' },
    { key: 'mc', value: 'MC', text: 'Monaco' },
    { key: 'md', value: 'MD', text: 'Moldova, Republic of' },
    { key: 'me', value: 'ME', text: 'Montenegro' },
    { key: 'mf', value: 'MF', text: 'Saint Martin (French part)' },
    { key: 'mg', value: 'MG', text: 'Madagascar' },
    { key: 'mh', value: 'MH', text: 'Marshall Islands' },
    { key: 'mk', value: 'MK', text: 'Macedonia, the former Yugoslav Republic of' },
    { key: 'ml', value: 'ML', text: 'Mali' },
    { key: 'mm', value: 'MM', text: 'Myanmar' },
    { key: 'mn', value: 'MN', text: 'Mongolia' },
    { key: 'mo', value: 'MO', text: 'Macao' },
    { key: 'mp', value: 'MP', text: 'Northern Mariana Islands' },
    { key: 'mq', value: 'MQ', text: 'Martinique' },
    { key: 'mr', value: 'MR', text: 'Mauritania' },
    { key: 'ms', value: 'MS', text: 'Montserrat' },
    { key: 'mt', value: 'MT', text: 'Malta' },
    { key: 'mu', value: 'MU', text: 'Mauritius' },
    { key: 'mv', value: 'MV', text: 'Maldives' },
    { key: 'mw', value: 'MW', text: 'Malawi' },
    { key: 'mx', value: 'MX', text: 'Mexico' },
    { key: 'my', value: 'MY', text: 'Malaysia' },
    { key: 'mz', value: 'MZ', text: 'Mozambique' },
    { key: 'na', value: 'NA', text: 'Namibia' },
    { key: 'nc', value: 'NC', text: 'New Caledonia' },
    { key: 'ne', value: 'NE', text: 'Niger' },
    { key: 'nf', value: 'NF', text: 'Norfolk Island' },
    { key: 'ng', value: 'NG', text: 'Nigeria' },
    { key: 'ni', value: 'NI', text: 'Nicaragua' },
    { key: 'nl', value: 'NL', text: 'Netherlands' },
    { key: 'no', value: 'NO', text: 'Norway' },
    { key: 'np', value: 'NP', text: 'Nepal' },
    { key: 'nr', value: 'NR', text: 'Nauru' },
    { key: 'nu', value: 'NU', text: 'Niue' },
    { key: 'nz', value: 'NZ', text: 'New Zealand' },
    { key: 'om', value: 'OM', text: 'Oman' },
    { key: 'pa', value: 'PA', text: 'Panama' },
    { key: 'pe', value: 'PE', text: 'Peru' },
    { key: 'pf', value: 'PF', text: 'French Polynesia' },
    { key: 'pg', value: 'PG', text: 'Papua New Guinea' },
    { key: 'ph', value: 'PH', text: 'Philippines' },
    { key: 'pk', value: 'PK', text: 'Pakistan' },
    { key: 'pl', value: 'PL', text: 'Poland' },
    { key: 'pm', value: 'PM', text: 'Saint Pierre and Miquelon' },
    { key: 'pn', value: 'PN', text: 'Pitcairn' },
    { key: 'pr', value: 'PR', text: 'Puerto Rico' },
    { key: 'ps', value: 'PS', text: 'Palestinian Territory, Occupied' },
    { key: 'pt', value: 'PT', text: 'Portugal' },
    { key: 'pw', value: 'PW', text: 'Palau' },
    { key: 'py', value: 'PY', text: 'Paraguay' },
    { key: 'qa', value: 'QA', text: 'Qatar' },
    { key: 're', value: 'RE', text: 'Reunion' },
    { key: 'ro', value: 'RO', text: 'Romania' },
    { key: 'rs', value: 'RS', text: 'Serbia' },
    { key: 'ru', value: 'RU', text: 'Russian Federation' },
    { key: 'rw', value: 'RW', text: 'Rwanda' },
    { key: 'sa', value: 'SA', text: 'Saudi Arabia' },
    { key: 'sb', value: 'SB', text: 'Solomon Islands' },
    { key: 'sc', value: 'SC', text: 'Seychelles' },
    { key: 'sd', value: 'SD', text: 'Sudan' },
    { key: 'se', value: 'SE', text: 'Sweden' },
    { key: 'sg', value: 'SG', text: 'Singapore' },
    { key: 'sh', value: 'SH', text: 'Saint Helena, Ascension and Tristan da Cunha' },
    { key: 'si', value: 'SI', text: 'Slovenia' },
    { key: 'sj', value: 'SJ', text: 'Svalbard and Jan Mayen' },
    { key: 'sk', value: 'SK', text: 'Slovakia' },
    { key: 'sl', value: 'SL', text: 'Sierra Leone' },
    { key: 'sm', value: 'SM', text: 'San Marino' },
    { key: 'sn', value: 'SN', text: 'Senegal' },
    { key: 'so', value: 'SO', text: 'Somalia' },
    { key: 'sr', value: 'SR', text: 'Suriname' },
    { key: 'ss', value: 'SS', text: 'South Sudan' },
    { key: 'st', value: 'ST', text: 'Sao Tome and Principe' },
    { key: 'sv', value: 'SV', text: 'El Salvador' },
    { key: 'sx', value: 'SX', text: 'Sint Maarten (Dutch part)' },
    { key: 'sy', value: 'SY', text: 'Syrian Arab Republic' },
    { key: 'sz', value: 'SZ', text: 'Swaziland' },
    { key: 'tc', value: 'TC', text: 'Turks and Caicos Islands' },
    { key: 'td', value: 'TD', text: 'Chad' },
    { key: 'tf', value: 'TF', text: 'French Southern Territories' },
    { key: 'tg', value: 'TG', text: 'Togo' },
    { key: 'th', value: 'TH', text: 'Thailand' },
    { key: 'tj', value: 'TJ', text: 'Tajikistan' },
    { key: 'tk', value: 'TK', text: 'Tokelau' },
    { key: 'tl', value: 'TL', text: 'Timor-Leste' },
    { key: 'tm', value: 'TM', text: 'Turkmenistan' },
    { key: 'tn', value: 'TN', text: 'Tunisia' },
    { key: 'to', value: 'TO', text: 'Tonga' },
    { key: 'tr', value: 'TR', text: 'Turkey' },
    { key: 'tt', value: 'TT', text: 'Trinidad and Tobago' },
    { key: 'tv', value: 'TV', text: 'Tuvalu' },
    { key: 'tw', value: 'TW', text: 'Taiwan, Province of China' },
    { key: 'tz', value: 'TZ', text: 'Tanzania, United Republic of' },
    { key: 'ua', value: 'UA', text: 'Ukraine' },
    { key: 'ug', value: 'UG', text: 'Uganda' },
    { key: 'um', value: 'UM', text: 'United States Minor Outlying Islands' },
    { key: 'uy', value: 'UY', text: 'Uruguay' },
    { key: 'uz', value: 'UZ', text: 'Uzbekistan' },
    { key: 'va', value: 'VA', text: 'Holy See (Vatican City State)' },
    { key: 'vc', value: 'VC', text: 'Saint Vincent and the Grenadines' },
    { key: 've', value: 'VE', text: 'Venezuela, Bolivarian Republic of' },
    { key: 'vg', value: 'VG', text: 'Virgin Islands, British' },
    { key: 'vi', value: 'VI', text: 'Virgin Islands, U.S.' },
    { key: 'vn', value: 'VN', text: 'Viet Nam' },
    { key: 'vu', value: 'VU', text: 'Vanuatu' },
    { key: 'wf', value: 'WF', text: 'Wallis and Futuna' },
    { key: 'ws', value: 'WS', text: 'Samoa' },
    { key: 'ye', value: 'YE', text: 'Yemen' },
    { key: 'yt', value: 'YT', text: 'Mayotte' },
    { key: 'za', value: 'ZA', text: 'South Africa' },
    { key: 'zm', value: 'ZM', text: 'Zambia' },
    { key: 'zw', value: 'ZW', text: 'Zimbabwe' },



  ];
  
  export default countryOptions;
  