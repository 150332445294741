// data/States.js

const stateOptions = [
    { key: 'al', value: 'Alabama', text: 'Alabama' },
    { key: 'ak', value: 'Alaska', text: 'Alaska' },
    { key: 'az', value: 'Arizona', text: 'Arizona' },
    { key: 'ar', value: 'Arkansas', text: 'Arkansas' },
    { key: 'ca', value: 'California', text: 'California' },
    { key: 'co', value: 'Colorado', text: 'Colorado' },
    { key: 'ct', value: 'Connecticut', text: 'Connecticut' },
    { key: 'de', value: 'Delaware', text: 'Delaware' },
    { key: 'dc', value: 'District of Columbia', text: 'District of Columbia' },
    { key: 'fl', value: 'Florida', text: 'Florida' },
    { key: 'ga', value: 'Georgia', text: 'Georgia' },
    { key: 'hi', value: 'Hawaii', text: 'Hawaii' },
    { key: 'id', value: 'Idaho', text: 'Idaho' },
    { key: 'il', value: 'Illinois', text: 'Illinois' },
    { key: 'in', value: 'Indiana', text: 'Indiana' },
    { key: 'ia', value: 'Iowa', text: 'Iowa' },
    { key: 'ks', value: 'Kansas', text: 'Kansas' },
    { key: 'ky', value: 'Kentucky', text: 'Kentucky' },
    { key: 'la', value: 'Louisiana', text: 'Louisiana' },
    { key: 'me', value: 'Maine', text: 'Maine' },
    { key: 'md', value: 'Maryland', text: 'Maryland' },
    { key: 'ma', value: 'Massachusetts', text: 'Massachusetts' },
    { key: 'mi', value: 'Michigan', text: 'Michigan' },
    { key: 'mn', value: 'Minnesota', text: 'Minnesota' },
    { key: 'ms', value: 'Mississippi', text: 'Mississippi' },
    { key: 'mo', value: 'Missouri', text: 'Missouri' },
    { key: 'mt', value: 'Montana', text: 'Montana' },
    { key: 'ne', value: 'Nebraska', text: 'Nebraska' },
    { key: 'nv', value: 'Nevada', text: 'Nevada' },
    { key: 'nh', value: 'New Hampshire', text: 'New Hampshire' },
    { key: 'nj', value: 'New Jersey', text: 'New Jersey' },
    { key: 'nm', value: 'New Mexico', text: 'New Mexico' },
    { key: 'ny', value: 'New York', text: 'New York' },
    { key: 'nc', value: 'North Carolina', text: 'North Carolina' },
    { key: 'nd', value: 'North Dakota', text: 'North Dakota' },
    { key: 'oh', value: 'Ohio', text: 'Ohio' },
    { key: 'ok', value: 'Oklahoma', text: 'Oklahoma' },
    { key: 'or', value: 'Oregon', text: 'Oregon' },
    { key: 'pa', value: 'Pennsylvania', text: 'Pennsylvania' },
    { key: 'pr', value: 'Puerto Rico', text: 'Puerto Rico' },
    { key: 'ri', value: 'Rhode Island', text: 'Rhode Island' },
    { key: 'sc', value: 'South Carolina', text: 'South Carolina' },
    { key: 'sd', value: 'South Dakota', text: 'South Dakota' },
    { key: 'tn', value: 'Tennessee', text: 'Tennessee' },
    { key: 'tx', value: 'Texas', text: 'Texas' },
    { key: 'ut', value: 'Utah', text: 'Utah' },
    { key: 'vt', value: 'Vermont', text: 'Vermont' },
    { key: 'vi', value: 'Virgin Islands', text: 'Virgin Islands' },
    { key: 'va', value: 'Virginia', text: 'Virginia' },
    { key: 'wa', value: 'Washington', text: 'Washington' },
    { key: 'wv', value: 'West Virginia', text: 'West Virginia' },
    { key: 'wi', value: 'Wisconsin', text: 'Wisconsin' },
    { key: 'wy', value: 'Wyoming', text: 'Wyoming' },
    
    // Add more states as needed, consider dynamic state options based on selected country
  ];
  
  export default stateOptions;
  